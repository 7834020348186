/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~font-awesome/css/font-awesome.min.css";

div.card-header {
    font-size: large;
}

div.card {
    margin-top: 10px
}

.table {
    margin-top: 10px
}

html, body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.authenticating-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    font-size: 3rem;
    background: #fff;
    opacity: 0.8;
    animation: pulsate 0.4s infinite alternate linear;
}

@keyframes pulsate {
    from { opacity: 1.0; }
    to { opacity: 0.2; }
}

/*
.pre {
    white-space: pre;
}

.break-all {
    word-break: break-all;
}

.table-props tr th {
    width: 1px;
}
 */